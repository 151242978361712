import React from "react";
import "./style.css";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";

const index = () => {
  return (
    <footer>
      <a href="#home" className="footer_logo">
        Prabin Baral
      </a>

      <ul className="permaLinks">
        <li>
          <a href="#home">Home</a>
        </li>

        <li>
          <a href="#about">About</a>
        </li>

        <li>
          <a href="#experience">Experiences</a>
        </li>

        <li>
          <a href="#services">Services</a>
        </li>

        <li>
          <a href="#portfolio">Portfolio</a>
        </li>

        <li>
          <a href="#testimonial">Testimonials</a>
        </li>

        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer_socials">
        <a href="https://www.linkedin.com/in/prabin-baral-947a951b6/">
          <BsLinkedin />
        </a>
        <a href="https://www.facebook.com/prabin.brll">
          <BsFacebook />
        </a>
        <a href="https://www.instagram.com/prabinbaral77/">
          <BsInstagram />
        </a>
        <a href="https://twitter.com/PrabinB18602809">
          <BsTwitter />
        </a>
      </div>

      <div className="footer_copyright">
        <small>&copy; Prabin Baral. All Rights Reserved.</small>
      </div>
    </footer>
  );
};

export default index;
