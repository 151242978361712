import React from "react";
import Header from "./components/header";
import Nav from "./components/nav/Nav";
import About from "./components/about";
import Experience from "./components/experience";
// import Services from "./components/services";
import Portfolio from "./components/portfolio";
import Testimonial from "./components/testimonial";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer";

const App = () => {
  return (
    <>
      <Header />
      <Nav />
      <About />
      <Experience />
      {/* <Services /> */}
      <Portfolio />
      <Testimonial />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
