import React from "react";
import "./style.css";
import AVT1 from "../../asset/client.jpeg";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const index = () => {
  const data = [
    {
      avatar: AVT1,
      name: "Roshan Jung Kunwar",
      review:
        "Customers and vendors enjoy the attractive, user-friendly website. My Web Programmer is always available to answer questions, solve issues, and offer advice. They act as a true business partner, delivering projects on time and keeping the company's long-term goals in mind.",
    },

    {
      avatar: AVT1,
      name: "Dhurba Subedi",
      review:
        "Customers and vendors enjoy the attractive, user-friendly website. My Web Programmer is always available to answer questions, solve issues, and offer advice. They act as a true business partner, delivering projects on time and keeping the company's long-term goals in mind.",
    },

    {
      avatar: AVT1,
      name: "Bibek Kc",
      review:
        "Customers and vendors enjoy the attractive, user-friendly website. My Web Programmer is always available to answer questions, solve issues, and offer advice. They act as a true business partner, delivering projects on time and keeping the company's long-term goals in mind.",
    },

    {
      avatar: AVT1,
      name: "Ram kumar Thapa",
      review:
        "Customers and vendors enjoy the attractive, user-friendly website. My Web Programmer is always available to answer questions, solve issues, and offer advice. They act as a true business partner, delivering projects on time and keeping the company's long-term goals in mind.",
    },

    {
      avatar: AVT1,
      name: "Mahendra Jung Kunwar",
      review:
        "Customers and vendors enjoy the attractive, user-friendly website. My Web Programmer is always available to answer questions, solve issues, and offer advice. They act as a true business partner, delivering projects on time and keeping the company's long-term goals in mind.",
    },
  ];
  return (
    <section id="testimonial">
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper
        className="container testimonial_container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide className="testimonial" key={index}>
              <div className="client_avatar">
                <img src={avatar} alt="Client One" className="client_image" />
              </div>
              <h5 className="client_name">{name}</h5>
              <small className="client_review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default index;
