import React, { useState } from "react";
import "./style.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine, RiContactsBookFill } from "react-icons/ri";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sucessful, setSucessful] = useState("");

  const templateParams = {
    name: name,
    email: email,
    message: message,
  };

  const SendEmail = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_jpahubc",
        "template_1jz160d",
        templateParams,
        "kf0UvD6z1pTY-LTXS"
      )
      .then(
        (response) => {
          setName("");
          setEmail("");
          setMessage("");
          setSucessful("Message Send Sucesssfully.");
        },
        (err) => {
          setName("");
          setEmail("");
          setMessage("");
        }
      );
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact_container">
        <div className="contact_options">
          <article className="contact_option">
            <MdOutlineEmail className="contact_option-icon" />
            <h4>Email</h4>
            <h5>prabin.baral00@gmial.com</h5>
            <a href="mailto:prabin.baral00@gmail.com">Send a message</a>
          </article>

          <article className="contact_option">
            <RiMessengerLine className="contact_option-icon" />
            <h4>Messanger</h4>
            <h5>Prabin Baral</h5>
            <a href="https://m.me/prabin.brll">Send a message</a>
          </article>

          <article className="contact_option">
            <RiContactsBookFill className="contact_option-icon" />
            <h4>Phone No</h4>
            <h5>+977 9846868438</h5>
            <a
              href="https://www.linkedin.com/in/prabin-baral-947a951b6/"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article>
        </div>
        <form onSubmit={SendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            name="message"
            placeholder="Your Message"
            required
            rows="7"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
          {sucessful !== "" ? (
            <p className="sucessfulMessage">{sucessful}</p>
          ) : null}
        </form>
      </div>
    </section>
  );
};

export default Contact;
