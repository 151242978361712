import React from "react";
import CTA from "./CTA";
import Social from "./Social";
import ProfileImg from "../../asset/home.gif";
import "./style.css";

const index = () => {
  return (
    <header id="home">
      <div className="container container_header">
        <h5 className="developer_name_section">Hello I`m</h5>
        <h1 className="name_developer">Prabin Baral</h1>
        <h5 className="text-light developer_name_section">
          BlockChain & React Js Developer
        </h5>

        <CTA />

        <Social />

        <div className="me">
          <img src={ProfileImg} alt="profile img" />
        </div>

        <a href="#contact" className="scroll_down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default index;
