import React from "react";
import "./style.css";
import AirBnb from "../../asset/airbnb.jpeg";
import SchoolSite from "../../asset/schoolsite.jpg";
import Chat from "../../asset/chat.png";
import SMS from "../../asset/sms.jpg";
import API from "../../asset/api.jpg";

const index = () => {
  const data = [
    {
      id: 1,
      image: AirBnb,
      title: "Airbnb Clone",
      github: "https://github.com/Prabinbaral77/Airbnb-clone",
      demo: "https://airbnb-clone-kohl-omega.vercel.app/",
    },
    {
      id: 2,
      image: SchoolSite,
      title: "School site In Next",
      github: "https://github.com/Prabinbaral77/School-Site-MERN-STACK",
      demo: "https://school-site-mern-stack.vercel.app/",
    },
    {
      id: 3,
      image: Chat,
      title: "Chat App",
      github: "https://github.com/Prabinbaral77/node-chat-app",
      demo: "https://baral-chat-app.herokuapp.com/",
    },
    {
      id: 4,
      image: SMS,
      title: "School Management System",
      github: "https://github.com/Prabinbaral77/School-Management-System",
      demo: "https://github.com/Prabinbaral77/School-Management-System",
    },
    {
      id: 5,
      image: API,
      title: "Task Manager Api",
      github: "https://github.com/Prabinbaral77/task-manager-api",
      demo: "https://github.com/Prabinbaral77/task-manager-api",
    },
  ];
  return (
    <section id="portfolio">
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio_container">
        {data.map(({ image, id, github, title, demo }) => (
          <article className="portfolio_item" key={id}>
            <div className="portfolio_item-image">
              <img src={image} alt={title} />
            </div>
            <h3>{title}</h3>
            <div className="portfolio_item-cta">
              <a href={github} target="_blank" rel="noreferrer" className="btn">
                Github
              </a>
              <a
                href={demo}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Live Demo
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default index;
