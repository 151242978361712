import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub, FaTwitter, FaFacebook } from "react-icons/fa";

const Social = () => {
  return (
    <div className="header_social">
      <a
        href="https://www.linkedin.com/in/prabin-baral-947a951b6/"
        rel="noreferrer"
        target="_blank"
      >
        {" "}
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/Prabinbaral77"
        rel="noreferrer"
        target="_blank"
      >
        {" "}
        <FaGithub />
      </a>
      <a
        href="https://twitter.com/PrabinB18602809"
        rel="noreferrer"
        target="_blank"
      >
        <FaTwitter />
      </a>
      <a
        href="https://www.facebook.com/prabin.brll"
        rel="noreferrer"
        target="_blank"
      >
        {" "}
        <FaFacebook />
      </a>
    </div>
  );
};

export default Social;
