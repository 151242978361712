import React from "react";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import "./style.css";
import ImageMe from "../../asset/Hand coding (1).gif";

const index = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ImageMe} alt="my img" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className="about_icon" />
              <h5>Experiences</h5>
              <small>1+ Years Working</small>
            </article>

            <article className="about_card">
              <FiUsers className="about_icon" />
              <h5>Clients</h5>
              <small>5+ client worldwide</small>
            </article>

            <article className="about_card">
              <VscFolderLibrary className="about_icon" />
              <h5>Projects</h5>
              <small>15+ Completed Projects </small>
            </article>
          </div>
          <p className="developer_details">
            Hello everyone, myself Prabin Baral from Pokhara-13, Nepal. I am
            currently studying Bachelor in Computer Engineering from Pokhara
            Engineering College(PEC) affiliated on Pokhara University. I have
            studied +2 level at Bhadrakali higher secondary school and school
            level at Ratan Panday Secondary School. I have learning the
            programming from different online resource and done different
            project for college and for challange myself. I have great desire to
            learn new technology and highly want to join and work with real life
            project on the large company.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let`s Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default index;
